<template>
  <div class="gome_wrap">
    <div class="bg_c">
      <div class="img_title">
        <h3>家生活 更精彩</h3>
        <h6>致力于中国家庭客群的消费需求升级与品质体验</h6>
      </div>
    </div>
    <!-- -------------------------------- -->
    <div class="gome_introduce">
        <div class="left_card">
          <h3 class="left_card-title">国美家介绍</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="right_card">
          <p>
            <span>国美家</span>是国美集团旗下聚焦“新零售、新服务、全客群”,为家庭用户提供全新的生活消费体验的平台公司。国美家围绕“家 · 生活”战略打造线上/线下融合发展的本地生活数字化平台——乐活秀，聚焦项目周边1-8KM家庭客群，线上依托于乐活秀APP/小程序，到网、到家、到店商户全量入驻，线下依托国美家门店及周边生活服务点，通过商品展示体验、家延伸服务、家娱乐社交三大功能板块，为家庭用户提供全新的生活消费体验。
          </p>
        </div>
      </div>
    <!-- -------------------------------- -->

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.gome_wrap {
  width: 100%;
//   min-height: 18000px;
  overflow-x: auto;
  .bg_c {
    background: url("~@/assets/img/bg_business.png") no-repeat center 0;
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: center;
      .img_title {
        width: 756px;
        height: 155px;
        margin: auto;
      }
      .img_title h3 {
        height: 98px;
        font-size: 70px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 15.56px;
      }
      .img_title h6 {
        width: 756px;
        height: 37px;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        opacity: 1;
        letter-spacing: 10px;
        margin-top: 20px;
      }
  }
  .gome_introduce {
    width: 1200px;
    margin: auto;
    position: relative;
    .left_card {
      padding-top: 78px;
      padding-bottom: 78px;
      opacity: 1;
      &-title {
        font-size: 40px;
        font-family: Hiragino Sans GB;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
        color: #1f2329;
        opacity: 1;
      }
      .bottom_line {
        width: 60px;
        height: 8px;
        background: linear-gradient(to right, #f6ba4f, #ea8427);
        border-radius: 4px;
        opacity: 1;
        text-align: bottom;
        top: 10px;
        margin: auto;
        position: relative;
      }
    }
    .right_card {
      padding-top: 0px;
      padding-bottom: 149px;
      p > span {
        display: inline-block;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 38px;
        box-align: baseline;
        margin-right: 15px;
        color: #1f2329;
      }
      p {
        margin-bottom: 40px;
        display: inline-block;
        float: none;
        // text-indent: 1em;//首行缩进
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
        box-align: baseline;
        color: #666666;
        opacity: 1;
      }
    }
  }

}
</style>
